.bars {
    width: 6px;
    height: auto;
    border-radius: 4px;
    display: block;
    margin: 20px auto;
    position: relative;
    background: currentColor;
    box-sizing: border-box;
    animation: animbars 0.6s 0.6s linear infinite alternate;
}

.bars::after,
.bars::before {
    content: '';
    width: 6px;
    height: auto;
    border-radius: 4px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animbars 0.6s 0.45s linear infinite alternate;
}
.bars::before {
    left: -20px;
    animation-delay: 0s;
}

@keyframes animbars {
    0% {
        height: 36px;
    }
    100% {
        height: 4px;
    }
}
