@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select {
        @apply w-full border-gray-300 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500;
    }
    [type='checkbox'],
    [type='radio'] {
        @apply border-gray-300 rounded text-indigo-600 focus:ring-indigo-500;
    }
}
